import { environment } from '../../../environments/environment';

export const ApiPath = {
    authors: environment.apiUrl + '/author',
    bookmarks: environment.apiUrl + '/bookmark',
    bookmarksAdd: environment.apiUrl + '/bookmark_add',
    bookmarksRemove: environment.apiUrl + '/bookmark_remove',
    events: environment.apiUrl + '/event',
    playlist: environment.apiUrl + '/playlist',
    comments: environment.apiUrl + '/comment',
    lectures: environment.apiUrl + '/lecture',
    slides: environment.apiUrl + '/slide',
    history: environment.apiUrl + '/history',
    historyAdd: environment.apiUrl + '/history_add',
    follow: environment.apiUrl + '/follow',
    followAdd: environment.apiUrl + '/follow_add',
    followRemove: environment.apiUrl + '/follow_remove',
    search: environment.apiUrl + '/search',
    notes: environment.apiUrl + '/note',
    auth: environment.apiUrl + '/auth',
    forms: environment.apiUrl + '/forms',
    category: environment.apiUrl + '/category',
    v2Videos: environment.apiUrl + '/v2/videos',
}
